export default class ColorConverter {
    static RGBToHSL(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
        const l = Math.max(r, g, b);
        const s = l - Math.min(r, g, b);
        const h = s
            ? l === r
                ? (g - b) / s
                : l === g
                    ? 2 + (b - r) / s
                    : 4 + (r - g) / s
            : 0;
        return {
            h: 60 * h < 0 ? 60 * h + 360 : 60 * h,
            s: 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
            l: (100 * (2 * l - s)) / 2,
        };
    }

    static RGBToHSV(r, g, b) {
        let rr, gg, bb, h = 0, s;
        const rAbs = r / 255;
        const gAbs = g / 255;
        const bAbs = b / 255;
        const v = Math.max(rAbs, gAbs, bAbs);
        const diff = v - Math.min(rAbs, gAbs, bAbs);
        const diffC = (c) => (v - c) / 6 / diff + 1 / 2;
        const percentRoundFn = (num) => Math.round(num * 100) / 100;
        if (diff === 0) {
            h = s = 0;
        } else {
            s = diff / v;
            rr = diffC(rAbs);
            gg = diffC(gAbs);
            bb = diffC(bAbs);

            if (rAbs === v) {
                h = bb - gg;
            } else if (gAbs === v) {
                h = (1 / 3) + rr - bb;
            } else if (bAbs === v) {
                h = (2 / 3) + gg - rr;
            }
            if (h < 0) {
                h += 1;
            } else if (h > 1) {
                h -= 1;
            }
        }
        return {
            h: Math.round(h * 360),
            s: percentRoundFn(s * 100),
            v: percentRoundFn(v * 100)
        };
    }

    static RGBStringToRGB(rgbString) {
        const rgb = rgbString.split(',');
        if (rgb.length !== 3 && rgb.length !== 4) {
            throw new Error('Invalid RGB string');
        }
        if (rgb.length === 4) {
            return {
                r: parseInt(rgb[0].split('(')[1].trim()),
                g: parseInt(rgb[1].trim()),
                b: parseInt(rgb[2].trim()),
                a: parseFloat(rgb[3].split(')')[0].trim()),
            };
        }
        return {
            r: parseInt(rgb[0].split('(')[1].trim()),
            g: parseInt(rgb[1].trim()),
            b: parseInt(rgb[2].split(')')[0].trim()),
            a: 1,
        };
    }

    static RGBToHex(r, g, b, a = 1) {
        let hex = `#${[r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')}`;
        if (a !== 1) {
            hex += `${Math.round(a * 255).toString(16).padStart(2, '0')}`;
        }
        return hex;
    }

    static HSLToRGB(hue, saturation, lightness) {
        if (hue === 360) {
            hue = 0;
        }
        const chroma = (1 - Math.abs((2 * lightness) - 1)) * saturation;
        let huePrime = hue / 60;
        const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1));

        huePrime = Math.floor(huePrime);
        let red = 0, green = 0, blue = 0;
        switch (huePrime) {
        case 0:
            red = chroma;
            green = secondComponent;
            break;
        case 1:
            red = secondComponent;
            green = chroma;
            break;
        case 2:
            green = chroma;
            blue = secondComponent;
            break;
        case 3:
            green = secondComponent;
            blue = chroma;
            break;
        case 4:
            red = secondComponent;
            blue = chroma;
            break;
        case 5:
            red = chroma;
            blue = secondComponent;
            break;
        }

        const lightnessAdjustment = lightness - (chroma / 2);
        red += lightnessAdjustment;
        green += lightnessAdjustment;
        blue += lightnessAdjustment;

        return {r: Math.round(red * 255), g: Math.round(green * 255), b: Math.round(blue * 255)};
    }

    static HexToRGB(hex) {
        if (hex.startsWith('#')) {
            hex = hex.slice(1);
        }
        if (hex.length === 3 || hex.length === 4) {
            hex = hex
                .split('')
                .map((char) => char + char)
                .join('');
        }
        if (hex.length === 6) {
            hex = hex + 'ff';
        }
        if (hex.length === 8) {
            return {
                r: parseInt(hex.slice(0, 2), 16),
                g: parseInt(hex.slice(2, 4), 16),
                b: parseInt(hex.slice(4, 6), 16),
                a: parseInt(hex.slice(6, 8), 16) / 255
            };
        }
        return {r: 0, g: 0, b: 0, a: 1};
    }

    static HSLToHex(h, s, l) {
        l /= 100;
        const f = (n) => {
            const k = (n + h / 30) % 12;
            const color = l - (s * Math.min(l, 1 - l) / 100) * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    static HexToHSL(hex) {
        const rgb = ColorConverter.HexToRGB(hex);
        return ColorConverter.RGBToHSL(rgb.r, rgb.g, rgb.b);
    }

    static RGBToHWB(r, g, b) {
        let h = ColorConverter.RGBToHSV(r, g, b).h;
        const w = 1 / 255 * Math.min(r, Math.min(g, b));
        const b2 = 1 - 1 / 255 * Math.max(r, Math.max(g, b));
        return {h, w, b: b2};
    }

    static HWBToRGB(h, w, b) {
        const hue = h / 360;
        let wh = w / 100;
        let bl = b / 100;
        const ratio = wh + bl;
        let f;

        // Wh + bl cant be > 1
        if (ratio > 1) {
            wh /= ratio;
            bl /= ratio;
        }

        const i = Math.floor(6 * hue);
        const v = 1 - bl;
        f = 6 * hue - i;

        if ((i & 0x01) !== 0) {
            f = 1 - f;
        }

        const n = wh + f * (v - wh);

        let r;
        let g;
        let b2;
        switch (i) {
        default:
        case 6:
        case 0:
            r = v;
            g = n;
            b2 = wh;
            break;
        case 1:
            r = n;
            g = v;
            b2 = wh;
            break;
        case 2:
            r = wh;
            g = v;
            b2 = n;
            break;
        case 3:
            r = wh;
            g = n;
            b2 = v;
            break;
        case 4:
            r = n;
            g = wh;
            b2 = v;
            break;
        case 5:
            r = v;
            g = wh;
            b2 = n;
            break;
        }
        return {r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b2 * 255)};
    }

    static HexToHWB(hex) {
        const rgb = ColorConverter.HexToRGB(hex);
        return ColorConverter.RGBToHWB(rgb.r, rgb.g, rgb.b);
    }

    static HWBToHex(h, w, b) {
        const rgb = ColorConverter.HWBToRGB(h, w, b);
        return ColorConverter.RGBToHex(rgb.r, rgb.g, rgb.b);
    }
}
