import 'flowbite';
import {__, trans, trans_choice} from './modules/translations.js';
import ColorConverter from "./modules/color-converter.js";

window.__ = __;
window.trans = trans;
window.trans_choice = trans_choice;

if (!('ColorConverter' in window)) {
    window.ColorConverter = ColorConverter;
}
